nav,
header,
section,
footer,
aside,
article,
div,
p,
a,
ul,
ol,
figure,
address,
img,
main,
input,
span,
li {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
p,
figure,
ul,
ol,
button {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4 {
  font-weight: normal;
}
select {
  cursor: pointer;
}
.flexbox--centered,
.flexbox-column--centered,
.login {
  justify-content: center;
  align-items: center;
}
.flexbox,
.flexbox--centered,
.login__text-button,
.login__check-button,
.login__check-button--disabled {
  display: flex;
  flex-flow: row nowrap;
}
.flexbox-column,
.flexbox-column--centered,
.login,
.login__container {
  display: flex;
  flex-flow: column nowrap;
}
.stretched {
  width: 100%;
  height: 100%;
}
body {
  font-family: sans-serif;
  background: radial-gradient(circle farthest-corner at 50% 32%, #047cb3 0, #015175 50%, #003046 100%);
  overflow: hidden;
  height: 100vh;
}
.login {
  padding: 5em 0 0 0;
}
.login__logo-image--disabled {
  display: none;
}
.login__header {
  color: #fff;
  font-size: 1.5em;
  margin: 0 0 1.5em;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.85);
}
.login__header img {
  object-fit: contain;
  max-width: 10em;
}
.login__container {
  background: #fff;
  box-shadow: 0 2px 6px 1px #00364f;
  width: 22em;
  padding: 1.2em;
  border-radius: 8px;
}
.login__container img {
  object-fit: contain;
  max-width: 17em;
  align-self: center;
  margin: 0 0 1.2em;
}
.login__input,
.login__input--bad-highlight {
  padding: 0.6em 0.4em 0.3em;
  margin: 0 0 0.8em 0;
  border: 0 solid rgba(0,0,0,0.13);
  box-shadow: inset 0 0 3px rgba(0,0,0,0.43);
  border-bottom-width: 1px;
  border-radius: 3px;
}
.login__input--bad-highlight {
  outline: 1px auto #f00;
}
.login__error-message,
.login__error-message--hidden {
  display: block;
  font-size: 0.9em;
  color: rgba(255,0,0,0.55);
  text-align: justify;
  border: 1px solid rgba(0,0,0,0.161);
  border-radius: 3px;
  height: 0px;
  overflow: hidden;
}
.login__error-message {
  opacity: 1;
  margin: 0 0 0.8em;
  padding: 0.6em 0.4em;
}
.login__error-message--hidden {
  opacity: 0;
  margin: 0;
  padding: 0;
}
.login__text-button {
  justify-content: center;
  padding: 0 0.2em 0.8em;
  background: none;
  color: #1e6ec1;
  border: none;
  cursor: pointer;
}
.login__text-button:focus {
  outline: 0;
}
.login__text-button:active .login__text-button__text {
  color: #000;
}
.login__check-button,
.login__check-button--disabled {
  justify-content: center;
  background: linear-gradient(to top, #dadada, #fafafa);
  padding: 0.8em 0.2em;
  border: 1px solid #bbb;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(0,0,0,0.39);
  cursor: pointer;
}
.login__check-button__text,
.login__check-button--disabled__text {
  margin: 0 0.5em 0 0;
}
.login__check-button:focus,
.login__check-button--disabled:focus {
  outline: 0;
}
.login__check-button {
  box-shadow: inset 1px 0 #fff;
}
.login__check-button:active {
  box-shadow: none;
  background: rgba(0,0,0,0.21);
}
.login__check-button--disabled {
  background: #f5f5f5;
  cursor: default;
  color: rgba(0,0,0,0.22);
}
.login__check-button--disabled .login__check-button__check-icon {
  display: none;
}
