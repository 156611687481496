@require 'base/init'
@require 'base/utilities'

$LOGIN_HEADER_COLOR = white
$LOGIN_MAIN_BACKGROUND = white
$LOGIN_INPUT_BORDER_COLOR = rgba(0, 0, 0, 0.13)

$FORM_FIELD_MARGIN_SIZE = 0.8em

body
    font-family: sans-serif
    background: radial-gradient(circle farthest-corner at 50% 32%, #047cb3 0, #015175 50%, #003046 100%)
    overflow: hidden
    height: 100vh

.login
    @extends .flexbox-column--centered
    padding: 5em 0 0 0

    &__logo-image
        &--disabled
            display: none

    &__header
        color: $LOGIN_HEADER_COLOR
        font-size: 1.5em
        margin: 0 0 1.5em
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.85)

        img
            object-fit: contain
            max-width: 10em

    &__container
        @extends .flexbox-column
        background: $LOGIN_MAIN_BACKGROUND
        box-shadow: 0 2px 6px 1px rgb(0, 54, 79)
        width: 22em
        padding: 1.2em
        border-radius: 8px

        img
            object-fit: contain
            max-width: 17em
            align-self: center
            margin: 0 0 1.2em

    &__input
        &,
        &--bad-highlight
            padding: 0.6em 0.4em 0.3em
            margin: 0 0 $FORM_FIELD_MARGIN_SIZE 0
            border: 0 solid $LOGIN_INPUT_BORDER_COLOR
            box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.43)
            border-bottom-width: 1px
            border-radius: 3px

        &--bad-highlight
            outline: 1px auto red

    &__error-message
        &,
        &--hidden
            display: block
            font-size: 0.9em
            color: rgba(255, 0, 0, 0.55)
            text-align: justify
            border: 1px solid #00000029
            border-radius: 3px
            height: 0px
            overflow: hidden

        &
            opacity: 1
            margin: 0 0 0.8em
            padding: 0.6em 0.4em

        &--hidden
            opacity: 0
            margin: 0
            padding: 0

    &__text-button
        @extends .flexbox
        justify-content: center
        padding: 0 0.2em 0.8em
        background: none
        color: #1e6ec1
        border: none
        cursor: pointer

        &:focus
            outline: 0

        &:active &__text
            color: black

    &__check-button
        &,
        &--disabled
            @extends .flexbox
            justify-content: center
            background: linear-gradient(to top, #dadada, #fafafa)
            padding: 0.8em 0.2em
            border: 1px solid #bbb
            border-radius: 3px
            text-transform: uppercase
            font-weight: bold
            color: rgba(0, 0, 0, 0.39)
            cursor: pointer

            &__text
                margin: 0 0.5em 0 0

            &:focus
                outline: 0

        &
            box-shadow: inset 1px 0 #fff

            &:active
                box-shadow: none
                background: rgba(0, 0, 0, 0.21)

        &--disabled
            background: #f5f5f5
            cursor: default
            color: rgba(0, 0, 0, 0.22)

        &--disabled &__check-icon
            display: none
