/* Sizing */
nav,
header,
section,
footer,
aside,
article,
div,
p,
a,
ul,
ol,
figure,
address,
img,
main,
input,
span,
li
  box-sizing: border-box

body,
h1,
h2,
h3,
h4,
p,
figure,
ul,
ol,
button
    margin: 0
    padding: 0

h1,
h2,
h3,
h4
    font-weight: normal

select
    cursor: pointer
