$--centered
    justify-content: center
    align-items: center


.flexbox
    display: flex;
    flex-flow: row nowrap;

.flexbox--centered
    @extend .flexbox, $--centered

.flexbox-column
    display: flex;
    flex-flow: column nowrap;

.flexbox-column--centered
    @extend .flexbox-column, $--centered

.stretched {
    width: 100%
    height: 100%
}
